@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Beatrice';
  src: url('/src/assets/fonts/Beatrice\ Regular.otf') format('otf');
  font-weight: normal;
  font-style: normal;
}

@layer utilities {

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.animated-background {
  background-size: 300%;

  -webkit-animation: animation 12s ease infinite;
  -moz-animation: animation 12s ease infinite;
  animation: animation 12s ease infinite;
}

@keyframes animation {

  0%,
  100% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }
}

.glow-overlay {
  --glow-size: 25rem;
  position: absolute;
  inset: 0;
  pointer-events: none;
  user-select: none;
  opacity: var(--glow-opacity, 0);
  mask: radial-gradient(var(--glow-size) var(--glow-size) at var(--glow-x, 50%) var(--glow-y, 50%),
      var(--glow-color) 1%,
      transparent 50%);
  transition: 400ms mask ease;
  will-change: mask;
}

.glow-overlay::before {
  content: '';
  position: absolute;
  inset: 0;
  background: radial-gradient(circle at var(--glow-x, 50%) var(--glow-y, 50%),
      var(--glow-color) 0%,
      transparent 60%);
  opacity: var(--glow-opacity, 0);
  transition: opacity 400ms ease;
  pointer-events: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}